<template>
  <div>
    <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{ backgroundImage: 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }"
              >
                {{ $store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : '' }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav v-if="!getUrlParameter('programa') && !getUrlParameter('turma')">
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos')"
              >Meus cursos</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Detalhes do curso</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Menu principal</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos')"
              >Meus cursos</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Detalhes do curso</a>
            </li>
          </ul>
        </nav>
        <nav v-else>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turmas')"
              >Minhas turmas</a>
            </li>            
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + parseInt(base64decode(getUrlParameter('turma'))) + '/programa/' + parseInt(base64decode(getUrlParameter('programa'))) + '?matricula=' + base64encode(parseInt(base64decode(getUrlParameter('matricula'))))"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + parseInt(base64decode(getUrlParameter('turma'))) + '/programa/' + parseInt(base64decode(getUrlParameter('programa'))) + '?matricula=' + base64encode(parseInt(base64decode(getUrlParameter('matricula')))))"
              >Turma</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Detalhes da unidade curricular</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div>
        <div
          id="body_conteudo_modo_ead"
          class="fd-app-wrap pt-0"
        >
          <input
            id="hdnDataInicioCurso"
            type="hidden"
            value
          >
          <input
            id="hdnDataFimCurso"
            type="hidden"
            value
          >
          <!-- fd-wrap -->
          <div class="con-wrap">
            <div class="con-content">
              <div class="container">
                <div v-if="fastCarregamentoCompleto" class="row justify-content-center">
                  <div class="col-12">
                    <!-- con-timeline-curso -->
                    <section class="con-timeline-curso fast-iuea-bgcolor">
                      <div class="con-timeline-curso-list">
                        <!-- Video Aulas -->
                        <div
                          v-if="fastCursoDetalhes.numero_videos"
                          class="con-timeline-curso-list-item"
                        >                          
                          <b-icon-collection-play-fill
                            font-scale="3"
                            variant="white"
                          />
                          <p>
                            <span
                              id="curso_numero_videos"
                              style
                            >{{ fastCursoDetalhes.numero_videos }} Video Aulas</span>
                          </p>
                        </div>
                        <!-- Exercícios -->
                        <div
                          v-if="fastCursoDetalhes.numero_quizes"
                          class="con-timeline-curso-list-item"
                        >
                          <b-icon-ui-checks
                            font-scale="3"
                            variant="white"
                          />
                          <p>
                            <span
                              id="curso_numero_quizes"
                              style
                            >{{ fastCursoDetalhes.numero_quizes }} Quizes</span>
                          </p>
                        </div>
                        <!-- Audio Aulas -->
                        <div
                          v-if="fastCursoDetalhes.numero_audios"
                          class="con-timeline-curso-list-item"
                        >
                          <b-icon-volume-up
                            font-scale="3"
                            variant="white"
                          />
                          <p>
                            <span
                              id="curso_numero_audios"
                              style
                            >{{ fastCursoDetalhes.numero_audios }} Audio Aulas</span>
                          </p>
                        </div>
                        <!-- Arquivos -->
                        <div
                          v-if="fastCursoDetalhes.numero_arquivos"
                          class="con-timeline-curso-list-item"
                        >
                          <b-icon-file-earmark-text
                            font-scale="3"
                            variant="white"
                          />
                          <p>
                            <span
                              id="curso_numero_arquivos"
                              style
                            >{{ fastCursoDetalhes.numero_arquivos }} Arquivos</span>
                          </p>
                        </div>
                        <!-- Lives -->
                        <div
                          v-if="fastCursoDetalhes.numero_lives"
                          class="con-timeline-curso-list-item"
                        >
                          <b-icon-camera-video
                            font-scale="3"
                            variant="white"
                          />
                          <p>
                            <span
                              id="curso_numero_quizes"
                              style
                            >{{ fastCursoDetalhes.numero_lives }} Lives</span>
                          </p>
                        </div>
                        <!-- Aula mestre -->
                        <div
                          v-if="fastCursoDetalhes.numero_aula_mestre"
                          class="con-timeline-curso-list-item"
                        >
                          <b-icon-camera-video
                            font-scale="3"
                            variant="white"
                          />
                          <p>
                            <span id="curso_numero_aula_mestre">
                              <img
                                src
                                style="width: 50px"
                              >
                            </span>
                          </p>
                        </div>
                        <!-- Pesquisas -->
                        <div
                          v-if="fastCursoDetalhes.numero_pesquisas"
                          class="con-timeline-curso-list-item"
                        >
                          <b-icon-clipboard-data
                            font-scale="3"
                            variant="white"
                          />
                          <p>
                            <span
                              id="curso_numero_pesquisas"
                              style
                            >{{ fastCursoDetalhes.numero_pesquisas }} Pesquisas</span>
                          </p>
                        </div>
                        <!-- Avaliações -->
                        <div
                          v-if="fastCursoDetalhes.numero_avaliacoes"
                          class="con-timeline-curso-list-item"
                        >
                          <b-icon-stopwatch
                            font-scale="3"
                            variant="white"
                          />
                          <p>
                            <span
                              id="curso_numero_avaliacoes"
                              style
                            >{{ fastCursoDetalhes.numero_avaliacoes }} Avaliações</span>
                          </p>
                        </div>
                        <!-- Certificado -->
                        <div
                          v-if="fastCursoCertificado"
                          class="con-timeline-curso-list-item mr-4"
                        >
                          <b-icon-award
                            font-scale="3"
                            variant="white"
                          />
                          <p>
                            <span id="curso_certificado">Certificado</span>
                          </p>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div class="card-body text-center pl-0 pr-0 pt-0">
                        <div
                          class="progress"
                          style="height: 30px;border-radius: 0 0 .25rem .25rem;"
                        >
                          <div
                            id="divBarraProgressoCurso"
                            class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            :aria-valuenow="fastCursoPorcentagem"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style="width: 0%"
                            :style="{ width: fastCursoPorcentagem + '%' }"
                          >
                            <span class="ml-2">{{ fastCursoPorcentagem }}% Completado</span>
                          </div>
                        </div>
                      </div>
                    </section>
                    <!-- /con-timeline-curso -->
                    <section class="con-info-curso mt-2">
                      <div class="row justify-content-between">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h2
                            v-if="!getUrlParameter('programa') && !getUrlParameter('turma')"
                            class="aluno_font_color"
                          >
                            Detalhes do curso
                          </h2>
                          <h2
                            v-else
                            class="aluno_font_color"
                          >
                            Detalhes da unidade curricular
                          </h2>
                          <h3 id="titulo_curso">
                            {{ fastCursoPlataforma.nome_curso }}
                          </h3>
                          <p id="detalhe_curso">
                            {{ fastCursoPlataforma.detalhe_curso }}
                          </p>
                          <div
                            id="logo_curso"
                            class="col-sm-12 col-md-12 col-lg-12 pl-0"
                          >
                            <img
                              class="img-fluid"
                              :src="ajustaLinkApiAntiga(fastCursoPlataforma.logo_curso)"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="con-info-curso-docentes mb-4">
                            <div
                              class="con-info-curso-docentes-body d-none fast-menu-lives-aluno-iuea"
                            >
                              <h4 class="fast-iuea-color">
                                Mentores
                              </h4>

                              <ul class="con-info-curso-list">
                                <li
                                  v-for="professor in fastCursoProfessores"
                                  :key="professor.id_professor"
                                  class="con-info-curso-list-item d-flex align-items-center"
                                >
                                  <img
                                    class="avatar"
                                    :src="ajustaLinkImageUser(professor.image)"
                                  >
                                  <span>
                                    {{ professor.first_name }} {{ professor.last_name }}
                                    <br>

                                    <small v-if="professor.licenciatura == '1'">Graduado</small>
                                    <small v-else-if="professor.licenciatura == '2'">Especialista</small>
                                    <small v-else-if="professor.licenciatura == '3'">Mestre</small>
                                    <small v-else-if="professor.licenciatura == '4'">Doutor</small>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div v-if="!iniciandoCursoLoading">
                          
                            <div v-if="!fastCursoIniciado">
                              <div class="con-info-curso-start">
                                <a
                                  v-if="!getUrlParameter('programa') && !getUrlParameter('turma')"
                                  href="#"
                                  class="btn btn-start-curso btn-lg btn-block fast-iuea-bgcolor"
                                  @click.prevent="iniciaFastUsuarioCurso()"
                                >Iniciar plano de estudo</a>
                                <a
                                  v-else
                                  href="#"
                                  class="btn btn-start-curso btn-lg btn-block fast-iuea-bgcolor"
                                  @click.prevent="iniciaFastUsuarioCurso()"
                                >Iniciar unidade curricular</a>
                              </div>
                            </div>
                            <div v-else>                            
                              <div class="con-info-curso-start">
                                <a
                                  v-if="!getUrlParameter('programa') && !getUrlParameter('turma')"
                                  href="#"
                                  class="btn btn-start-curso btn-lg btn-block fast-iuea-bgcolor"
                                  @click.prevent="continuarCurso()"
                                >Continuar plano de estudo</a>
                                <a
                                  v-else
                                  href="#"
                                  class="btn btn-start-curso btn-lg btn-block fast-iuea-bgcolor"
                                  @click.prevent="continuarCurso()"
                                >Continuar unidade curricular</a>
                              </div>
                              <div
                                v-if="fastCursoReiniciar"
                                class="con-info-curso-start mt-2"
                              >
                                <a
                                  v-if="!getUrlParameter('programa') && !getUrlParameter('turma')"
                                  href="#"
                                  class="btn btn-lg btn-block btn-danger"
                                  @click.prevent="showModal('modalReiniciarCurso')"
                                >Reiniciar plano de estudo</a>
                                <a
                                  v-else
                                  href="#"
                                  class="btn btn-lg btn-block btn-danger"
                                  @click.prevent="showModal('modalReiniciarCurso')"
                                >Reiniciar unidade curricular</a>
                              </div>                              
                            </div>

                          </div>
                          <div v-else class="text-center">

                            <h3><b-icon
                              icon="gear-fill"
                              animation="spin"
                            /> Carregando</h3>

                          </div>

                          <div class="con-info-curso-docentes fast-plataforma-iuea-hide">
                            <div class="con-info-curso-docentes-header">
                              <h4 v-if="!getUrlParameter('programa') && !getUrlParameter('turma')">
                                Tutor do curso
                              </h4>
                              <h4 v-else>
                                Tutor
                              </h4>
                              <div
                                v-if="fastCursoTutor.tutor"
                                class="con-info-curso-tutor d-flex align-items-center"
                              >
                                <img
                                  class="avatar"
                                  :src="ajustaLinkImageUser(fastCursoTutor.image)"
                                >
                                <span>{{ fastCursoTutor.first_name }} {{ fastCursoTutor.last_name }}</span>
                              </div>
                              <div
                                v-else
                                class="con-info-curso-tutor d-flex align-items-center"
                              >
                                <p>Nenhum tutor cadastrado</p>
                              </div>
                            </div>
                            <div class="con-info-curso-docentes-body">
                              <h4 v-if="!getUrlParameter('programa') && !getUrlParameter('turma')">
                                Professores do Plano de Estudo
                              </h4>
                              <h4 v-else>
                                Professores da unidade curricular
                              </h4>
                              <ul class="con-info-curso-list">
                                <li
                                  v-for="professor in fastCursoProfessores"
                                  :key="professor.id_professor"
                                  class="con-info-curso-list-item d-flex align-items-center"
                                >
                                  <img
                                    class="avatar"
                                    :src="ajustaLinkImageUser(professor.image)"
                                  >
                                  <span>
                                    {{ professor.first_name }} {{ professor.last_name }}
                                    <br>

                                    <small v-if="professor.licenciatura == '1'">Graduado</small>
                                    <small v-else-if="professor.licenciatura == '2'">Especialista</small>
                                    <small v-else-if="professor.licenciatura == '3'">Mestre</small>
                                    <small v-else-if="professor.licenciatura == '4'">Doutor</small>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div
                              v-if="fastCursoDisciplinas.length"
                              class="con-info-curso-docentes-body"
                            >
                              <h4>Disciplinas</h4>
                              <ul class="con-info-curso-list">
                                <li
                                  v-for="d in fastCursoDisciplinas"
                                  :key="d.id_area_disciplina_plataforma"
                                  class="con-info-curso-list-item d-flex align-items-center"
                                >
                                  <span>
                                    <b-icon-bookmarks-fill />
                                    {{ d.descricao }}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class="con-info-curso-start mt-3">
                            <span
                              v-if="fastCursoCertificado"
                              style
                              class="btn btn-warning text-white"
                              @click="showModal('modalStatusCertificado')"
                            >Status do certificado</span>

                            <!-- #modal-status-certificado -->
                            <div
                              id="modal-status-certificado"
                              class="modal fade"
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div
                                class="modal-dialog modal-lg"
                                role="document"
                              >
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h3 class="modal-title">
                                      Status do certificado
                                    </h3>
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div
                                      id="divModalStatusCertificado"
                                      class="col-12"
                                    >
                                      <span
                                        class="text-danger"
                                      >Não é possível emitir o certificado, pois sua nota não atingiu a média do curso.</span>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      id="btnVisualizarCertificado"
                                      type="button"
                                      class="btn btn-primary"
                                      style="display: none"
                                    >
                                      Download
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- #modal-status-certificado -->
                          </div>

                          <!-- con-aulas-curso -->
                          <div v-if="fastDomain == 'iuea.fastead.com'">
                            <section class="con-aulas-curso mt-4" style="overflow: auto">
                              <div class="con-aulas-curso-header">
                                <div class="d-flex align-items-center">
                                  <h3
                                    class="fast-menu-lives-aluno-fast"
                                  >
                                    Aulas ({{ fastCursoAulasFiltro.length }})
                                  </h3>
                                  <h3 class="d-none fast-menu-lives-aluno-iuea" />
                                </div>
                              </div>
                              <div class="con-aulas-curso-body">
                                <ul class="con-aulas-curso-list">
                                  <li
                                    v-for="aula in pageOfItems"
                                    :key="aula.id_aula"
                                    :class="aula.classe"
                                  >
                                    <div class="d-flex align-items-center">
                                      <div
                                        class="con-aulas-curso-list-item-icon d-flex align-items-center justify-content-center"
                                      >
                                        <b-icon-play
                                          variant="white"
                                          font-scale="2"
                                        />
                                      </div>

                                      <span class="con-aulas-curso-list-item-text w-100">
                                        <a
                                          v-if="aula.status"
                                          :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + fastCursoPlataforma.id_curso + '/aula/' + aula.id_aula"
                                        >
                                          <h4 class="fast-iuea-color">{{ aula.titulo_aula }}</h4>
                                        </a>
                                        <a
                                          v-else
                                          href="#"
                                          @click.prevent="acessaNovaAula(aula)"
                                        >
                                          <h4 class="fast-iuea-color">{{ aula.titulo_aula }}</h4>
                                        </a>
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </section>
                            <div class="col-12 text-center mt-4 fast-plataforma-iuea-hide">
                              <Pagination
                                :page-size="50"
                                :items="fastCursoAulasFiltro"
                                @changePage="pageOfItems = $event"
                              />
                            </div>
                          </div>
                          <!-- /con-aulas-curso -->
                        </div>
                      </div>
                    </section>
                    <!-- con-aulas-curso -->
                    <div class="row mt-4">
                      <div
                        v-if="fastDomain != 'iuea.fastead.com'"
                        class="col-sm-12 col-md-12 col-lg-12"
                      >
                        <section class="con-aulas-curso mt-4" style="overflow: auto">
                          <div class="con-aulas-curso-header">
                            <div class="d-flex align-items-center">
                              <h3
                                class="fast-menu-lives-aluno-fast"
                              >
                                Aulas ({{ fastCursoAulasFiltro.length }})
                              </h3>
                              <h3 class="d-none fast-menu-lives-aluno-iuea" />
                            </div>
                          </div>
                          <div class="con-aulas-curso-body">
                            <ul class="con-aulas-curso-list">
                              <li
                                v-for="aula in pageOfItems"
                                :key="aula.id_aula"
                                :class="aula.classe"
                              >
                                <div class="d-flex align-items-center">
                                  <div
                                    class="con-aulas-curso-list-item-icon d-flex align-items-center justify-content-center"
                                  >
                                    <b-icon-play
                                      variant="white"
                                      font-scale="2"
                                    />
                                  </div>

                                  <span
                                    v-if="!getUrlParameter('turma') && !getUrlParameter('programa')"
                                    class="con-aulas-curso-list-item-text w-100"
                                  >
                                    <a
                                      v-if="aula.status"
                                      :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + fastCursoPlataforma.id_curso + '/aula/' + aula.id_aula"
                                    >
                                      <h4 class="fast-iuea-color">{{ aula.titulo_aula }}</h4>
                                    </a>
                                    <a
                                      v-else
                                      href="#"
                                      @click.prevent="acessaNovaAula(aula)"
                                    >
                                      <h4 class="fast-iuea-color">{{ aula.titulo_aula }}</h4>
                                    </a>
                                  </span>
                                  <span
                                    v-else
                                    class="con-aulas-curso-list-item-text w-100"
                                  >
                                    <a
                                      v-if="aula.status"
                                      :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos/curso/' + fastCursoPlataforma.id_curso + '/aula/' + aula.id_aula + '?turma=' + base64encode(parseInt(base64decode(getUrlParameter('turma')))) + '&programa=' + base64encode(parseInt(base64decode(getUrlParameter('programa')))) + '&matricula=' + base64encode(parseInt(base64decode(getUrlParameter('matricula'))))"
                                    >
                                      <h4 class="fast-iuea-color">{{ aula.titulo_aula }}</h4>
                                    </a>
                                    <a
                                      v-else
                                      href="#"
                                      @click.prevent="acessaNovaAula(aula)"
                                    >
                                      <h4 class="fast-iuea-color">{{ aula.titulo_aula }}</h4>
                                    </a>
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </section>
                        <div class="col-12 text-center mt-4 fast-plataforma-iuea-hide">
                          <Pagination
                            :page-size="50"
                            :items="fastCursoAulasFiltro"
                            @changePage="pageOfItems = $event"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- /con-aulas-curso -->
                    <div class="col-12 mt-4">
                      <a
                        v-if="getUrlParameter('programa') && getUrlParameter('turma')"
                        class="btn btn-red-hollow fast-iuea-ml-20" 
                        :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + parseInt(base64decode(getUrlParameter('turma'))) + '/programa/' + parseInt(base64decode(getUrlParameter('programa'))) + '?matricula=' + base64encode(parseInt(base64decode(getUrlParameter('matricula'))))"
                      ><b-icon-arrow-return-left /> Voltar</a>
                      <a
                        v-else                        
                        class="btn btn-red-hollow fast-iuea-ml-20"
                        :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/meus-cursos'"
                      >
                        <b-icon-arrow-return-left /> Voltar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <VueHtml2pdf
      ref="html2Pdf"
      :show-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      filename="Certificado"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
    >
      <section slot="pdf-content">
        <div v-html="fastCertificadoUsuario.html_certificado" />
      </section>
    </VueHtml2pdf>
    <!-- Modals -->
    <modal
      name="modalStatusCertificado"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Status do certificado</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalStatusCertificado')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 mt-4 pl-4 pr-4">
          <h6
            v-if="fastCursoAprovacao == 'aprovado'"
            class="text-success"
          >
            {{ fastCursoStatusAprovacao }}
          </h6>
          <h6
            v-else-if="fastCursoAprovacao == 'reprovado'"
            class="text-danger"
          >
            {{ fastCursoStatusAprovacao }}
          </h6>

          <div
            v-if="fastCertificadoUsuario.id_certificado"
            class="mt-4"
          >
            <button
              class="btn btn-success"
              @click="emiteCertificadoUsuario"
            >
              Gerar certificado
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalReiniciarCurso"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
      :width="modalWidthSm"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-12 pl-4 pr-4">
          <h4>Reiniciar curso?</h4>
        </div>

        <div class="col-12 mt-4 pl-4 pr-4">
          <button
            class="btn btn-danger mr-2"
            @click="reiniciaFastUsuarioCurso()"
          >
            <small>Confirmar</small>
          </button>
          <button
            class="btn btn-secondary"
            @click="hideModal('modalReiniciarCurso')"
          >
            <small>Cancelar</small>
          </button>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import VueHtml2pdf from "vue-html2pdf"; //https://www.npmjs.com/package/vue-html2pdf
import muralAvisos from "../components/MuralAvisos";

export default {
  name: "HomeInternoAluno",
  components: {
    Pagination,
    VueHtml2pdf,
    muralAvisos,
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      fastDomain: settings.fastDomain,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // FastCurso
      fastCursoPlataforma: {
        acesso: "",
        carga_horaria: "",
        data_disponivel: "",
        data_expiracao: "",
        detalhe_curso: "",
        id_curso: 0,
        id_plataforma: 0,
        id_registro_curso_plataforma: "",
        id_requisicao: "",
        id_usuario_responsavel: "",
        libera_automatica: "",
        logo_curso: "",
        multidisciplinar: "",
        nome_curso: "",
        origem: "",
        publicado: "",
        quantidade: "",
      },
      fastCursoDetalhes: {},
      fastCursoTutor: {},
      fastCursoProfessores: [],
      fastCursoPorcentagem: 0,
      fastCursoCertificado: false,
      fastCursoAprovacao: "",
      fastCursoStatusAprovacao: "",
      fastCursoAulasTotal: [],
      fastCursoAulasFiltro: [],
      fastCursoIniciado: false,
      pageOfItems: [],
      // FasAula
      fastAulaAtual: {
        id_aula: "",
      },
      fastCertificadoUsuario: {
        id_certificado: "",
        id_curso: 0,
        html_certificado: "",
      },
      fastUsuarioCurso: [],
      fastRedirecionaAula: false,
      // Disciplinas
      fastCursoDisciplinas: [],
      fastCursoReiniciar: 0,
      fastCarregamentoCompleto: false,
      iniciandoCursoLoading: false
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma && this.$route.params.id_curso) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          if (this.getUrlParameter('programa') && this.getUrlParameter('turma')) {
            this.exibeFastCursoDetalhesSecretaria();
          } else {
            this.exibeFastCursoDetalhes();
          }
          this.getFastCursoPlataforma();
          this.getFastCertificado();
          this.exibeAulasCurso();

          this.exibeTutorPlanoCurso();
          this.exibeFastCursoProfessores();

          this.buscaEstatisticasUsuarioCurso()
            .then((u) => {
              this.fastUsuarioCurso = u;
              if (u[0]) this.fastCursoReiniciar = u[0].reiniciar              
            })
            .catch((e) => {
              console.log("then", e);
            });
          if (this.fastDomain != "iuea.fastead.com")
            this.getFastDisciplinasCurso();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastCursoPlataforma() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_plataforma/lista_curso_aluno?id_usuario=&id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objCurso = Array.from(json);
        if (objCurso.length) {
          objCurso[0].nome_curso = objCurso[0].nome_curso ? objCurso[0].nome_curso : objCurso[0].nome_curso_mae
          objCurso[0].detalhe_curso = objCurso[0].detalhe_curso ? objCurso[0].detalhe_curso : objCurso[0].detalhe_curso_mae
          objCurso[0].carga_horaria = objCurso[0].carga_horaria ? objCurso[0].carga_horaria : objCurso[0].carga_horaria_mae
          objCurso[0].logo_curso = objCurso[0].logo_curso ? objCurso[0].logo_curso : objCurso[0].logo_curso_mae

          this.fastCursoPlataforma = objCurso[0];
        } else {
          this.fastCursoPlataforma = [];
        }

      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastCertificado() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso/verifica_certificado?id_curso=" +
            this.$route.params.id_curso +
            "&id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastCursoPorcentagem = obj[0].status.split(" ").pop();
          let certificado = obj[0].status.split(",")[0];

          if (certificado == "Curso com certificado") {
            this.fastCursoCertificado = true;
            this.fastCursoAprovacao = obj[0].status.split(" ")[3];

            // Aprovado com nota
            if (this.fastCursoAprovacao == "aprovado") {
              this.fastCursoStatusAprovacao =
                "Parabéns! Você  atingiu a média do curso.";

              // Busca certificado e prepara botão de emissão
              this.getCertificadoUsuario();

              // Reprovado devido a nota
            } else if (this.fastCursoAprovacao == "reprovado") {
              this.fastCursoStatusAprovacao =
                "Não é possível emitir o certificado, pois sua nota não atingiu a média do curso.";

              // Certificado não depende de nota
            } else if (this.fastCursoAprovacao == "progresso") {
              // Completou 100%
              if (parseInt(this.fastCursoPorcentagem) == 100) {
                this.fastCursoStatusAprovacao =
                  "Parabéns! Você completou o curso.";
                // Busca certificado e prepara botão de emissão
                this.getCertificadoUsuario();
              } else {
                this.fastCursoStatusAprovacao =
                  "Para emitir o certificado você deve completar o curso.";
              }
            }
          }
          //console.log("getFastCertificado", obj);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getCertificadoUsuario() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso/lista_certificado?id_curso=" +
            this.$route.params.id_curso +
            "&id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastCertificadoUsuario = obj[0];
          let certificado = this.fastCertificadoUsuario.html_certificado;
          certificado = certificado.replace(
            "[nome_aluno]",
            this.retornaSessao(settings.fastSessaoUsuario).First_name +
              " " +
              this.retornaSessao(settings.fastSessaoUsuario).Last_name
          );
          certificado = certificado.replace(
            "[nome_curso]",
            this.fastCursoDetalhes.nome_curso
          );
          certificado = certificado.replace(
            "[carga_horaria]",
            this.fastCursoDetalhes.carga_horaria + " horas"
          );
          certificado = certificado.replace(
            "[data_inicio]",
            new Date(this.fastUsuarioCurso[0].data_inicio_curso)
              .toLocaleString()
              .split(" ")[0]
          );

          certificado = certificado.replace("height: 600px;", "");

          let data_fim_atividade = "";
          this.fastUsuarioCurso.forEach((d, index) => {
            if (index == 0) {
              data_fim_atividade = new Date(d.data_fim_atividade);
            } else {
              if (new Date(d.data_fim_atividade) > new Date(data_fim_atividade))
                data_fim_atividade = d.data_fim_atividade;
            }
          });
          certificado = certificado.replace(
            "[data_conclusao]",
            new Date(data_fim_atividade).toLocaleString().split(" ")[0]
          );
          this.fastCertificadoUsuario.html_certificado = certificado;
        }
        //console.log("getCertificadoUsuario", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    emiteCertificadoUsuario() {
      this.$refs.html2Pdf.generatePdf();
    },
    async buscaEstatisticasUsuarioCurso() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_usuario_curso/estatisticas_usuario?id_usuario_aluno=" +
              this.retornaSessao(settings.fastSessaoUsuario).Id_Usuario +
              "&id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_curso=" +
              this.$route.params.id_curso,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          console.log("buscaEstatisticasUsuarioCurso", json);
          if (json.length) {
            resolve(json);
          } else {
            reject(0);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    async reiniciaFastUsuarioCurso() {
      
      let fast_curso_plataforma = {
        id_plataforma: this.$route.params.id_plataforma,
        id_curso: this.$route.params.id_curso,
      };
      this.$store.state.fastCarregando = true
      this.iniciandoCursoLoading = true
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_usuario_curso/reinicia",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
        );
        let json = await resp.json();
        //console.log(json);
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Curso reiniciado com sucesso",
        });
        this.fastRedirecionaAula = true;
        this.exibeAulasCurso();
        //this.hideModal("modalReiniciarCurso");
        //this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
        this.iniciandoCursoLoading = false
      }
    },
    async iniciaFastUsuarioCurso() {
      this.$store.state.fastCarregando = true
      this.iniciandoCursoLoading = true
      let fast_curso_plataforma = {
        id_plataforma: this.$route.params.id_plataforma,
        id_curso: this.$route.params.id_curso,
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_usuario_curso/reinicia",
          this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
        );
        let json = await resp.json();
        if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
          // Decodifica base64, converte pra INT e codifica novamente para prevenir XSS
          this.$router.push("/plataforma/" +
          this.$route.params.id_plataforma +
          "/aluno/meus-cursos/curso/" +
          this.fastCursoPlataforma.id_curso +
          "/aula/" +
          this.fastAulaAtual.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
        } else {
          this.$router.push("/plataforma/" +
          this.$route.params.id_plataforma +
          "/aluno/meus-cursos/curso/" +
          this.fastCursoPlataforma.id_curso +
          "/aula/" +
          this.fastAulaAtual.id_aula);
        }
        
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
        this.iniciandoCursoLoading = false
      }
    },
    async exibeAulasCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso_aula/lista?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);

        this.exibeAulasCursoPendentes(obj);
        //console.log("exibeAulasCurso", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async exibeAulasCursoPendentes(aulas) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso_aula/lista_pendentes_curso?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          if (aulas.length) {
            let aulaTotal = obj.concat(aulas);
            aulaTotal.forEach((a) => {
              if (a.status) {
                if (a.status == "E") {
                  a.classe = "con-aulas-curso-list-item em_andamento";
                } else if (a.status == "F") {
                  a.classe = "con-aulas-curso-list-item concluido";
                }
              } else {
                a.classe = "con-aulas-curso-list-item";
              }
            });
            this.fastCursoAulasTotal = aulaTotal.sort(this.sortArrayAulas);
            this.fastCursoAulasFiltro = aulaTotal.sort(this.sortArrayAulas);
            this.fastCursoIniciado = true;
          } else {
            obj.forEach((a) => {
              if (a.status) {
                if (a.status == "E") {
                  a.classe = "con-aulas-curso-list-item em_andamento";
                } else if (a.status == "F") {
                  a.classe = "con-aulas-curso-list-item concluido";
                }
              } else {
                a.classe = "con-aulas-curso-list-item";
              }
            });
            this.fastCursoAulasTotal = obj;
            this.fastCursoAulasFiltro = obj;
          }
        } else {
          let aulas_finalizada = 0;
          if (aulas.length) {
            aulas.forEach((a) => {
              if (a.status) {
                if (a.status == "E") {
                  a.classe = "con-aulas-curso-list-item em_andamento";
                } else if (a.status == "F") {
                  a.classe = "con-aulas-curso-list-item concluido";
                  aulas_finalizada++;
                }
              } else {
                a.classe = "con-aulas-curso-list-item";
              }
            });
            if (aulas_finalizada == aulas.length) {
              this.promiseFinalizaCurso()
                .then(() => console.log("Curso finalizado"))
                .catch(() => console.log("Erro ao finalizar curso"));
            }
            this.fastCursoAulasTotal = aulas;
            this.fastCursoAulasFiltro = aulas;
            this.fastCursoIniciado = true;
          } else {
            this.fastCursoAulasTotal = [];
            this.fastCursoAulasFiltro = [];
          }
        }

        // Aula em execução tem prioridade
        this.fastCursoAulasTotal.some((a) => {
          if (a.status) {
            if (a.status == "E") {
              this.fastAulaAtual = a;
              return true;
            }
          }
        });
        // Pega a aula próxima a uma uma finalizada
        if (!this.fastAulaAtual.id_aula) {
          this.fastCursoAulasTotal.some((a, index) => {
            if (a.status) {
              if (a.status == "F") {
                if (this.fastCursoAulasTotal[index + 1]) {
                  this.fastAulaAtual = this.fastCursoAulasTotal[index + 1];
                  return true;
                }
              }
            }
          });
        }
        // Pega a primeira aula
        if (!this.fastAulaAtual.id_aula) {
          if (this.fastCursoAulasTotal.length)
            this.fastAulaAtual = this.fastCursoAulasTotal[0];
        }

        if (this.fastRedirecionaAula) {
          if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
            // Decodifica base64, converte pra INT e codifica novamente para prevenir XSS
            this.$router.push("/plataforma/" +
            this.$route.params.id_plataforma +
            "/aluno/meus-cursos/curso/" +
            this.$route.params.id_curso +
            "/aula/" +
            this.fastAulaAtual.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
          } else {
            this.$router.push("/plataforma/" +
            this.$route.params.id_plataforma +
            "/aluno/meus-cursos/curso/" +
            this.$route.params.id_curso +
            "/aula/" +
            this.fastAulaAtual.id_aula);
          }
          
        } else {
          // Informa ao component pai para interromper o loading
          this.$store.state.fastCarregando = false;
        }

        this.fastCarregamentoCompleto = true

        //console.log("exibeAulasCursoPendentes", this.fastAulaAtual);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async promiseFinalizaCurso() {
      return new Promise(async (resolve, reject) => {
        let fast_curso_plataforma = {
          id_curso: this.$route.params.id_curso,
          id_plataforma: this.$route.params.id_plataforma
        };
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_usuario_curso/finaliza",
            this.fastAjaxOptions("POST", JSON.stringify(fast_curso_plataforma))
          );
          let json = await resp.json();
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    },
    sortArrayAulas(a, b) {
      const sequenciaA = a.sequencia;
      const sequenciaB = b.sequencia;

      let comparison = 0;
      if (sequenciaA > sequenciaB) {
        comparison = 1;
      } else if (sequenciaA < sequenciaB) {
        comparison = -1;
      }
      return comparison;
    },
    async acessaNovaAula(aula) {
      if (this.fastCursoIniciado) {
        let fast_usuario_curso_aula = {
          id_aula: aula.id_aula,
          id_plataforma: this.$route.params.id_plataforma,
          fast_usuario_curso: {
            id_curso: this.$route.params.id_curso,
          },
        };
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_usuario_curso_aula/insere_aula",
            this.fastAjaxOptions(
              "POST",
              JSON.stringify(fast_usuario_curso_aula)
            )
          );
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {
            if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
            // Decodifica base64, converte pra INT e codifica novamente para prevenir XSS
            this.$router.push("/plataforma/" +
            this.$route.params.id_plataforma +
            "/aluno/meus-cursos/curso/" +
            this.fastCursoPlataforma.id_curso +
            "/aula/" +
            aula.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
          } else {
            this.$router.push("/plataforma/" +
              this.$route.params.id_plataforma +
              "/aluno/meus-cursos/curso/" +
              this.fastCursoPlataforma.id_curso +
              "/aula/" +
              aula.id_aula);
          }
            
          } else {
            this.$notify({
              group: "foo",
              type: "error",
              duration: 5000,
              speed: 1000,
              title: "Status",
              text: "Erro ao iniciar aula",
            });
          }
        } catch (e) {
          console.log("Erro", e);
        }
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text:
            "Você deve iniciar o plano de estudo antes de ver as aulas avulsas.",
        });
      }
    },
    continuarCurso() {
      if (this.fastAulaAtual.status) {
        if (this.getUrlParameter('turma') && this.getUrlParameter('programa')) {
            // Decodifica base64, converte pra INT e codifica novamente para prevenir XSS
            this.$router.push("/plataforma/" +
            this.$route.params.id_plataforma +
            "/aluno/meus-cursos/curso/" +
            this.fastCursoPlataforma.id_curso +
            "/aula/" +
            this.fastAulaAtual.id_aula + "?turma=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('turma')))) + "&programa=" + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('programa')))) + '&matricula=' + this.base64encode(parseInt(this.base64decode(this.getUrlParameter('matricula')))));
          } else {
            this.$router.push("/plataforma/" +
          this.$route.params.id_plataforma +
          "/aluno/meus-cursos/curso/" +
          this.fastCursoPlataforma.id_curso +
          "/aula/" +
          this.fastAulaAtual.id_aula);
          }
        
      } else {
        this.acessaNovaAula(this.fastAulaAtual);
      }
    },
    // Detalhes do curso
    async exibeTutorPlanoCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_professor/lista_tutor?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) this.fastCursoTutor = obj[0];
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async exibeFastCursoDetalhes() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista_destalhes?id_curso=" +
            this.$route.params.id_curso +
            "&id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objCurso = Array.from(json);
        if (objCurso.length) {
          objCurso[0].nome_curso = objCurso[0].nome_curso ? objCurso[0].nome_curso : objCurso[0].nome_curso_mae
          objCurso[0].detalhe_curso = objCurso[0].detalhe_curso ? objCurso[0].detalhe_curso : objCurso[0].detalhe_curso_mae
          objCurso[0].carga_horaria = objCurso[0].carga_horaria ? objCurso[0].carga_horaria : objCurso[0].carga_horaria_mae
          objCurso[0].logo_curso = objCurso[0].logo_curso ? objCurso[0].logo_curso : objCurso[0].logo_curso_mae
          this.fastCursoDetalhes = objCurso[0];
        }
        //console.log("exibeFastCursoDetalhes", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async exibeFastCursoDetalhesSecretaria() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso/lista_destalhes_secretaria?id_curso=" +
            this.$route.params.id_curso +
            "&id_plataforma=" +
            this.$route.params.id_plataforma +            
            "&id_programa_turma=" +
            parseInt(this.base64decode(this.getUrlParameter('programa'))),
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objCurso = Array.from(json);
        if (objCurso.length) {
          objCurso[0].nome_curso = objCurso[0].nome_curso ? objCurso[0].nome_curso : objCurso[0].nome_curso_mae
          objCurso[0].detalhe_curso = objCurso[0].detalhe_curso ? objCurso[0].detalhe_curso : objCurso[0].detalhe_curso_mae
          objCurso[0].carga_horaria = objCurso[0].carga_horaria ? objCurso[0].carga_horaria : objCurso[0].carga_horaria_mae
          objCurso[0].logo_curso = objCurso[0].logo_curso ? objCurso[0].logo_curso : objCurso[0].logo_curso_mae
          this.fastCursoDetalhes = objCurso[0];
        }
        //console.log("exibeFastCursoDetalhes", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async exibeFastCursoProfessores() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_professor/lista?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastCursoProfessores = obj;
        }
        //console.log("exibeFastCursoProfessores", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Disciplinas
    async getFastDisciplinasCurso() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_curso_disciplina_plataforma/lista?id_curso=" +
            this.$route.params.id_curso,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);

        if (obj.length) {
          this.fastCursoDisciplinas = obj;
        } else {
          this.fastCursoDisciplinas = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style scope>
/*
* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Myriad Pro", Myriad, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
*/
.con-wrap {
  padding-bottom: 100px;
}

.con-breadcrumb {
  list-style: none;
  padding-left: 0px;
}
.con-breadcrumb .con-breadcrumb-item {
  display: inline-block;
}
.con-breadcrumb .con-breadcrumb-item a {
  color: #777;
  font-size: 18px;
  font-style: italic;
  text-decoration: none;
}
@media (max-width: 768px) {
  .con-breadcrumb .con-breadcrumb-item a {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .con-breadcrumb .con-breadcrumb-item a {
    font-size: 12px;
  }
}
.con-breadcrumb .con-breadcrumb-item + .con-breadcrumb-item:before {
  content: "/";
  font-style: italic;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 767px) {
  .con-breadcrumb .con-breadcrumb-item + .con-breadcrumb-item:before {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.con-breadcrumb .active a {
  font-weight: bold;
  font-style: italic;
}

.con-modal .modal-content {
  border-radius: 0px;
  border: none;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.3);
}
.con-modal .modal-content .con-modal-body {
  padding: 30px 100px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-header img {
  width: 220px;
  margin-bottom: 20px;
}
.con-modal .modal-content .con-modal-body .con-modal-login-content h4 {
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
  color: #0089cb;
  font-size: 20px;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form {
  padding: 0px 30px;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs {
  margin-top: 20px;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  a {
  color: #a7a7a7;
  text-decoration: none;
  font-size: 10px;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  label {
  font-size: 12px;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  input[type="checkbox"]
  + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  input[type="checkbox"] {
  display: none;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  input[type="checkbox"]
  + label:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  border: 1.5px solid #454545;
  border-radius: 4px;
  width: 1em;
  height: 1em;
  padding: 2px;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  input[type="checkbox"]
  + label:active:before {
  transform: scale(0);
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  input[type="checkbox"]:checked
  + label:before {
  color: #0089cb;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  input[type="checkbox"]:checked:disabled
  + label:before {
  transform: scale(1);
  background-color: #0089cb;
  border-color: #0089cb;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  .input-celular {
  margin-top: 10px;
  margin-bottom: 10px;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  .input-celular
  label {
  margin-bottom: 0px;
  font-size: 16px;
  color: #2c2c2c;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  .input-celular
  .form-control {
  margin-top: -10px;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  .input-celular
  .form-control::placeholder {
  color: #b2b2b2;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  .form-control {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #2c2c2c;
  border-radius: 0px;
  font-size: 20px;
  padding: 20px 0px;
  color: #2c2c2c;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  .form-control::placeholder {
  color: #2c2c2c;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-inputs
  .form-control
  + .form-control {
  margin-top: 15px;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-footer
  small {
  color: #a7a7a7;
}
.con-modal
  .modal-content
  .con-modal-body
  .con-modal-login-content
  .con-modal-login-form
  .con-modal-login-form-footer
  small
  a {
  font-size: 14px;
  color: #a7a7a7;
  font-weight: bold;
  text-decoration: none;
}

.rating-stars ul {
  list-style-type: none;
  padding: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.rating-stars ul > li.star {
  display: inline-block;
}

/* Idle State of the stars */
.rating-stars ul > li.star > i.fa {
  font-size: 2em;
  /* Change the size of the stars */
  color: #ccc;
  /* Color on idle state */
}

/* Hover state of the stars */
.rating-stars ul > li.star.hover > i.fa {
  color: #ffcc36;
}

/* Selected state of the stars */
.rating-stars ul > li.star.selected > i.fa {
  color: #ffcc36;
}
.con-welcome {
  margin-top: 60px;
}
.con-welcome h1 {
  text-transform: uppercase;
  font-weight: 600;
  font-style: italic;
}
.con-welcome h1 span {
  font-size: 2.5rem;
}

.con-timeline-curso::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.con-timeline-curso::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.con-timeline-curso::-webkit-scrollbar-thumb {
  background: #76c2ff;
}

.con-timeline-curso::-webkit-scrollbar-thumb:hover {
  background: #76c2ff;
}

.con-timeline-curso::-webkit-scrollbar-thumb:active {
  background: #76c2ff;
}

.con-timeline-curso::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-bottom: 10px solid #e8e8e8;
}

.con-timeline-curso::-webkit-scrollbar-track:hover {
  background: #e8e8e8;
}

.con-timeline-curso::-webkit-scrollbar-track:active {
  background: #e8e8e8;
}

.con-timeline-curso::-webkit-scrollbar-corner {
  background: transparent;
}

.con-timeline-curso {
  background-color: #3889ca;
  padding: 30px 20px 20px 20px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.con-timeline-curso .con-timeline-curso-list {
  list-style: none;
}
.con-timeline-curso .con-timeline-curso-list .con-timeline-curso-list-item {
  display: inline-block;
  text-align: center;
}
.con-timeline-curso .con-timeline-curso-list .con-timeline-curso-list-item i {
  color: #fff;
}
.con-timeline-curso .con-timeline-curso-list .con-timeline-curso-list-item p {
  color: #fff;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.con-timeline-curso
  .con-timeline-curso-list
  .con-timeline-curso-list-item
  + .con-timeline-curso-list-item {
  margin-left: 55px;
}

.con-info-curso {
  margin-top: 80px;
}
.con-info-curso h2 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  font-style: italic;
}
.con-info-curso h3 {
  font-size: 20px;
  color: #3889ca;
  text-transform: uppercase;
  font-weight: 600;
  font-style: italic;
}
.con-info-curso h4 {
  font-size: 22px;
  color: #3889ca;
  text-transform: uppercase;
  font-weight: 600;
  font-style: italic;
}
.con-info-curso p {
  font-size: 18px;
  color: #323232;
}
.con-info-curso .con-info-curso-tags {
  list-style: none;
  padding-left: 0px;
}
.con-info-curso .con-info-curso-tags li {
  display: inline-block;
  background-color: #d8d8d8;
  border-radius: 50px;
  padding: 3px 12px;
  margin-top: 10px;
  font-size: 14px;
  color: #a4a4a4;
}
.con-info-curso .con-info-curso-start .btn {
  font-size: 20px;
}
.con-info-curso .con-info-curso-docentes {
  margin-top: 20px;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-header {
  background-color: #d8d8d8;
  padding: 10px;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-header
  .con-info-curso-tutor
  .avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-header
  .con-info-curso-tutor
  span {
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  color: #323232;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-header
  .con-info-curso-tutor
  span
  small {
  letter-spacing: 0px;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body::-webkit-scrollbar-thumb {
  background: #76c2ff;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body::-webkit-scrollbar-thumb:hover {
  background: #76c2ff;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body::-webkit-scrollbar-thumb:active {
  background: #76c2ff;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-bottom: 10px solid #e8e8e8;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body::-webkit-scrollbar-track:hover {
  background: #e8e8e8;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body::-webkit-scrollbar-track:active {
  background: #e8e8e8;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body::-webkit-scrollbar-corner {
  background: transparent;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body {
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 10px;
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
}
.con-info-curso .con-info-curso-docentes .con-info-curso-docentes-body h4 {
  font-size: 18px;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body
  .con-info-curso-list {
  padding-left: 0px;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body
  .con-info-curso-list
  .con-info-curso-list-item
  .avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
}
.con-info-curso
  .con-info-curso-docentes
  .con-info-curso-docentes-body
  .con-info-curso-list
  .con-info-curso-list-item
  + .con-info-curso-list-item {
  margin-top: 10px;
}
@media (max-width: 767px) {
  .con-info-curso {
    text-align: center;
  }
  .con-info-curso #logo_curso {
    padding: 0 !important;
  }
  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.con-aulas-curso {
  margin-top: 100px;
  position: relative;
}

.con-aulas-curso .con-aulas-curso-header h2 {
  font-size: 60px;
}
.con-aulas-curso .con-aulas-curso-header .btn-secondary {
  font-size: 25px;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-thumb {
  background: #76c2ff;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-thumb:hover {
  background: #76c2ff;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-thumb:active {
  background: #76c2ff;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-bottom: 10px solid #e8e8e8;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-track:hover {
  background: #e8e8e8;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-track:active {
  background: #e8e8e8;
}
.con-aulas-curso .con-aulas-curso-body::-webkit-scrollbar-corner {
  background: transparent;
}
.con-aulas-curso .con-aulas-curso-body {
  max-height: 550px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 0px;
  direction: rtl;
}
.con-aulas-curso .con-aulas-curso-body .con-aulas-curso-list {
  margin-top: 20px;
  list-style: none;
  padding-left: 50px;
  direction: ltr;
}
.con-aulas-curso
  .con-aulas-curso-body
  .con-aulas-curso-list
  .con-aulas-curso-list-item
  .con-aulas-curso-list-item-icon {
  background-color: #ccc;
  width: 50px;
  height: 45px;
  text-align: center;
  border-radius: 50%;
}
.con-aulas-curso
  .con-aulas-curso-body
  .con-aulas-curso-list
  .con-aulas-curso-list-item
  .con-aulas-curso-list-item-icon
  i {
  color: #fff;
  font-size: 50px;
  line-height: 90px;
}
.con-aulas-curso
  .con-aulas-curso-body
  .con-aulas-curso-list
  .con-aulas-curso-list-item
  .con-aulas-curso-list-item-text {
  margin-left: 30px;
}
.con-aulas-curso
  .con-aulas-curso-body
  .con-aulas-curso-list
  .con-aulas-curso-list-item
  .con-aulas-curso-list-item-text
  h4 {
  font-size: 25px;
  font-weight: 600;
  color: #a4a4a4;
}
.con-aulas-curso
  .con-aulas-curso-body
  .con-aulas-curso-list
  .con-aulas-curso-list-item
  .con-aulas-curso-list-item-text
  p {
  font-size: 20px;
  color: #3a3535;
}
.con-aulas-curso
  .con-aulas-curso-body
  .con-aulas-curso-list
  .con-aulas-curso-list-item
  + .con-aulas-curso-list-item {
  margin-top: 20px;
}
.con-aulas-curso
  .con-aulas-curso-body
  .con-aulas-curso-list
  .concluido
  .con-aulas-curso-list-item-icon {
  background-color: #1fcc1b;
}
.con-aulas-curso
  .con-aulas-curso-body
  .con-aulas-curso-list
  .em_andamento
  .con-aulas-curso-list-item-icon {
  background-color: #8ac6e9;
}
.con-aulas-curso
  .con-aulas-curso-body
  .con-aulas-curso-list
  .em_andamento
  .con-aulas-curso-list-item-text
  h4 {
  color: #8ac6e9;
}

.con-aulas-avaliacoes-curso {
  background-color: #42124c;
  margin-top: 50px;
  padding: 60px 0px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-header h2 {
  color: #fff;
  border-right: 3px solid #fff;
  font-size: 50px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-header h3 {
  color: #fff;
  font-size: 20px;
  font-style: italic;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.con-aulas-avaliacoes-curso .con-aulas-avaliacoes-curso-body {
  margin-top: 100px;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-thumb {
  background: #6a0c7e;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-thumb:hover {
  background: #6a0c7e;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-thumb:active {
  background: #6a0c7e;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-track {
  background: #33093c;
  border-bottom: 10px solid #33093c;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-track:hover {
  background: #33093c;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-track:active {
  background: #33093c;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list::-webkit-scrollbar-corner {
  background: transparent;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list {
  padding-left: 0px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 20px;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list
  .con-aulas-avaliacoes-curso-list-item {
  color: #fff;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list
  .con-aulas-avaliacoes-curso-list-item
  .row
  [class*="col-"] {
  padding: 0px 15px !important;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list
  .con-aulas-avaliacoes-curso-list-item
  span {
  margin-left: 10px;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list
  .con-aulas-avaliacoes-curso-list-item
  .avatar {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list
  .con-aulas-avaliacoes-curso-list-item
  .rating-stars
  ul
  > li.star
  > i.fa {
  font-size: 1.5em;
}
.con-aulas-avaliacoes-curso
  .con-aulas-avaliacoes-curso-body
  .con-aulas-avaliacoes-curso-list
  .con-aulas-avaliacoes-curso-list-item
  + .con-aulas-avaliacoes-curso-list-item {
  margin-top: 20px;
}

.con-aulas-cta-curso {
  margin-top: 30px;
}
.con-aulas-cta-curso .btn {
  font-size: 30px;
}

.con-aulas-relacionados-curso {
  margin-top: 150px;
}
.con-aulas-relacionados-curso .con-aulas-relacionados-curso-header h2 {
  font-size: 35px;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.con-aulas-relacionados-curso .con-aulas-relacionados-curso-header .btn {
  margin-left: 20px;
  font-size: 25px;
}
.con-aulas-relacionados-curso .con-aulas-relacionados-curso-body {
  margin-top: 80px;
}
.con-aulas-relacionados-curso .con-aulas-relacionados-curso-body .btn {
  margin-top: 20px;
}
.con-aulas-relacionados-curso
  .con-aulas-relacionados-curso-body
  .con-aulas-relacionados-curso-item
  .con-aulas-relacionados-curso-item-content {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px 5px 10px;
}
.con-aulas-relacionados-curso
  .con-aulas-relacionados-curso-body
  .con-aulas-relacionados-curso-item
  .con-aulas-relacionados-curso-item-content
  p {
  font-size: 14px;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .con-planos-estudo-header {
    min-height: 200px;
  }
}
.con-planos-estudo-header #text-part-1 h1 {
  font-size: 85px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  font-style: italic;
}
@media (max-width: 767px) {
  .con-planos-estudo-header #text-part-1 h1 {
    font-size: 40px;
  }
}
.con-planos-estudo-header #text-part-2 h1 {
  font-size: 85px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-style: italic;
}
@media (max-width: 767px) {
  .con-planos-estudo-header #text-part-2 h1 {
    font-size: 40px;
    text-align: right;
  }
}

@media (max-width: 767px) {
  .con-planos-enem {
    padding: 40px 0px 150px 0px;
    background-size: 250%;
  }
}
@media (max-width: 767px) {
  .con-planos-enem .con-planos-enem-list .con-planos-enem-list-item {
    margin-top: 20px;
  }
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-title {
  width: 100%;
  height: 75px;
  text-align: center;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-title
  h3 {
  color: #fff;
  letter-spacing: 2px;
  font-style: italic;
  font-weight: bold;
  font-size: 25px;
  text-transform: uppercase;
  line-height: 25px;
  padding-top: 25px;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-video {
  background-position: center;
  background-size: cover;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-video
  .con-planos-enem-list-item-video-overlay {
  width: 100%;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.2);
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-video
  .con-planos-enem-list-item-video-overlay
  i {
  color: #fff;
  font-size: 60px;
  margin-top: 20%;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-desc {
  background-color: #eee;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-desc
  hr {
  border-color: #42124c;
  width: 100%;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-desc
  .con-planos-enem-list-item-desc-body {
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-desc
  .con-planos-enem-list-item-desc-footer {
  padding-bottom: 10px;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-desc
  .con-planos-enem-list-item-desc-footer
  p {
  margin-bottom: 0px;
  font-weight: 800;
  font-style: italic;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 20px;
  color: #42124c;
  text-transform: uppercase;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-desc
  .con-planos-enem-list-item-desc-list {
  padding: 0px 30px;
  text-align: left;
  margin-bottom: 0px;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-desc
  .con-planos-enem-list-item-desc-list
  li {
  list-style: none;
  color: #42124c;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 16px;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-desc
  .con-planos-enem-list-item-desc-list
  li
  span {
  font-weight: 800;
  font-style: normal;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-desc
  .con-planos-enem-list-item-desc-list
  li:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  font-size: 16px;
  color: #3889ca;
  margin-left: -30px;
}
.con-planos-enem
  .con-planos-enem-list
  .con-planos-enem-list-item
  .con-planos-enem-list-item-desc
  .con-planos-enem-list-item-desc-list
  li
  + li {
  margin-top: 5px;
}
.con-planos-enem .con-aquecimento-color .btn-aquecimento {
  background-color: #ec1550;
  box-shadow: -3px 3px 0px #42124c;
  color: #fff;
  font-weight: 800;
  font-size: 22px;
}
.con-planos-enem .con-aquecimento-color .btn-aquecimento:focus,
.con-planos-enem .con-aquecimento-color .btn-aquecimento:hover {
  background-color: #ec1550;
  box-shadow: 0px;
  color: #42124c;
}
.con-planos-enem .con-aquecimento-color .con-planos-enem-list-item-title {
  background-color: #ec1550;
}
.con-planos-enem .con-extensivo-color .btn-extensivo {
  background-color: #1d69a5;
  box-shadow: -3px 3px 0px #42124c;
  color: #fff;
  font-weight: 800;
  font-size: 22px;
}
.con-planos-enem .con-extensivo-color .btn-extensivo:focus,
.con-planos-enem .con-extensivo-color .btn-extensivo:hover {
  background-color: #1d69a5;
  box-shadow: 0px;
  color: #42124c;
}
.con-planos-enem .con-extensivo-color .con-planos-enem-list-item-title {
  background-color: #1d69a5;
}
.con-planos-enem .con-semi-extensivo-color .con-planos-enem-list-item-title h3 {
  text-align: start;
  padding: 15px 40px 0px 35px !important;
}
.con-planos-enem .con-semi-extensivo-color .btn-semi-extensivo {
  background-color: #42124c;
  box-shadow: -3px 3px 0px #ec1550;
  color: #fff;
  font-weight: 800;
  font-size: 22px;
}
.con-planos-enem .con-semi-extensivo-color .btn-semi-extensivo:focus,
.con-planos-enem .con-semi-extensivo-color .btn-semi-extensivo:hover {
  background-color: #42124c;
  box-shadow: 0px;
  color: #ec1550;
}
.con-planos-enem .con-semi-extensivo-color .con-planos-enem-list-item-title {
  background-color: #42124c;
}
.con-planos-enem .con-intensivao-color .btn-intensivao {
  background-color: #195b8f;
  box-shadow: -3px 3px 0px #ec1550;
  color: #fff;
  font-weight: 800;
  font-size: 22px;
}
.con-planos-enem .con-intensivao-color .btn-intensivao:focus,
.con-planos-enem .con-intensivao-color .btn-intensivao:hover {
  background-color: #195b8f;
  box-shadow: 0px;
  color: #ec1550;
}
.con-planos-enem .con-intensivao-color .con-planos-enem-list-item-title {
  background-color: #195b8f;
}

.con-aulas-particular .con-aulas-particular-header {
  position: absolute;
  margin-top: -50px;
}
@media (max-width: 767px) {
  .con-aulas-particular .con-aulas-particular-header {
    margin-left: -30px;
  }
}
.con-aulas-particular .con-aulas-particular-header h2 {
  background-color: #ec1652;
  padding: 10px 40px;
  display: block;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
  font-weight: bolder;
  font-style: italic;
  font-size: 50px;
  position: relative;
}
@media (max-width: 767px) {
  .con-aulas-particular .con-aulas-particular-header h2 {
    font-size: 25px;
  }
}
.con-aulas-particular .con-aulas-particular-header h2:before {
  content: "";
  width: 100vw;
  height: 100%;
  position: absolute;
  background-color: #ec1652;
  top: 0;
  left: -100vw;
}
.con-aulas-particular .con-aulas-particular-body {
  padding-top: 200px;
  position: relative;
}
@media (max-width: 767px) {
  .con-aulas-particular .con-aulas-particular-body {
    padding-top: 50px;
  }
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list::-webkit-scrollbar-thumb {
  background: #6490a9;
  border-radius: 40px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list::-webkit-scrollbar-thumb:hover {
  background: #6490a9;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list::-webkit-scrollbar-thumb:active {
  background: #6490a9;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-bottom: 10px solid #e8e8e8;
  border-radius: 40px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list::-webkit-scrollbar-track:hover {
  background: #e8e8e8;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list::-webkit-scrollbar-track:active {
  background: #e8e8e8;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list::-webkit-scrollbar-corner {
  background: transparent;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .row {
  flex-wrap: nowrap;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item {
  position: relative;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  p {
  font-size: 14px;
  font-weight: bold;
  color: #95ccf9;
  margin-top: 10px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-classificacao {
  padding: 0px 30px;
  margin-top: 20px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-classificacao
  hr {
  border-color: #eee;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-classificacao
  p {
  color: #656565;
  margin-bottom: 2px;
  font-weight: bold;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-classificacao
  .questoes_respondidas {
  margin-top: -10px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-classificacao
  .rating-stars
  ul
  > li.star
  + li.star {
  margin-left: -7px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-classificacao
  .rating-stars
  ul
  > li.star
  > i.fa {
  font-size: 15px;
  margin-top: 10px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-estado {
  margin-top: -10px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-estado
  img {
  width: 30px;
  height: 20px;
  border-radius: 0px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-estado
  span {
  margin-top: -10px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-disciplina {
  padding: 0px 20px;
  margin-top: 20px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-disciplina
  label {
  /* Old browsers */
  background: -moz-linear-gradient(left, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #64c7eb 0%,
    #3ebbe3 51%,
    #23afd8 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  padding: 8px 40px;
  border-radius: 15px;
  color: #fff;
  font-family: "Ubuntu Bold";
  font-size: 18px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor
  .con-aulas-particular-professores-list-item-professor-disciplina
  hr {
  border-color: #eee;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-header {
  background-color: #ececec;
  padding: 10px 2px 20px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Ubuntu Regular";
  color: #7c7c7c;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-body {
  background-color: #fff;
  border-top: 1px solid #ececec;
  min-height: 150px;
  position: relative;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  padding-bottom: 10px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-body:before {
  content: "";
  height: 30px;
  width: 101%;
  left: -1px;
  background-color: #fff;
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -20px;
  position: absolute;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-body:after {
  content: "";
  height: 30px;
  width: 101%;
  bottom: -20px;
  left: -1px;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor-titulo {
  padding: 0px 20px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor-titulo
  p {
  color: #656565;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-professor-titulo
  div {
  border: 2px solid #edf2f0;
  border-radius: 2px;
  padding: 10px 20px;
  width: 100%;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-actions {
  padding: 0px 30px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-footer {
  background-color: #8ac6e9;
  padding: 30px 0px 15px 35px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-footer
  img {
  width: 40px;
}
.con-aulas-particular
  .con-aulas-particular-body
  .con-aulas-particular-professores-list
  .con-aulas-particular-professores-list-item
  .con-aulas-particular-professores-list-item-footer
  span {
  font-family: "Ubuntu Bold";
  line-height: 16px;
  margin-left: 10px;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}
.con-aulas-particular .carousel-multi-prev {
  left: -80px;
  margin-top: 35%;
}
.con-aulas-particular .carousel-multi-next {
  right: -80px;
  margin-top: 35%;
}
.con-aulas-particular .carousel-multi-prev,
.con-aulas-particular .carousel-multi-next {
  position: absolute;
}
@media (max-width: 767px) {
  .con-aulas-particular .carousel-multi-prev,
  .con-aulas-particular .carousel-multi-next {
    display: none;
  }
}

.btn-aulas-particular {
  background-color: #d98840;
  border-radius: 15px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 16px;
}
.btn-aulas-particular:hover {
  color: #fff;
}
.btn-aulas-particular img {
  width: 30px;
  margin-right: 3px;
}

.btn-aulas-particular + .btn-aulas-particular {
  margin-top: 10px;
}

.con-welcome-classroom {
  margin-top: 60px;
}
.con-welcome-classroom img {
  width: 220px;
}
.con-welcome-classroom h1 {
  font-family: "Ubuntu Regular";
  font-size: 50px;
}
.con-welcome-classroom h1 span {
  padding: 5px 5px 5px 5px;
  background-color: #66c7eb;
  border-radius: 20px;
  font-family: "Ubuntu Regular";
  font-size: 50px;
}

.con-name-classroom {
  margin-top: 30px;
}
.con-name-classroom p {
  color: #afafaf;
  font-family: "Ubuntu Regular";
  font-size: 30px;
}

.con-timeline {
  padding-left: 2%;
  position: relative;
}
.con-timeline:before {
  content: "";
  position: absolute;
  background: #cccccc;
  margin-top: 25px;
  width: 90%;
  height: 0.3px;
}
.con-timeline-classroom ul {
  margin-bottom: 0px;
}
.con-timeline-classroom ul li {
  display: inline-block;
  list-style-type: none;
  max-width: 60px;
  text-align: center;
}
.con-timeline-classroom ul li .con-timeline-classroom-icon {
  background-color: #ccc;
  border-radius: 50%;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  top: 5px;
}
.con-timeline-classroom ul li .con-timeline-classroom-icon i {
  font-size: 25px;
  line-height: 40px;
}
.con-timeline-classroom ul li p {
  font-size: 9.5px;
  color: #ccc;
  font-family: "Ubuntu Light";
  margin-left: -25%;
  margin-top: 90%;
}
.con-timeline-classroom ul li + li {
  margin-left: 12px;
}
.con-timeline-classroom-visualizado .con-timeline-classroom-icon {
  background: #1fcc1b !important;
  border-radius: 50%;
  top: 5px !important;
}
.con-timeline-classroom-assistindo .con-timeline-classroom-icon {
  background: #8ac6e9 !important;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  text-align: center;
  top: 0 !important;
}
.con-timeline-classroom-assistindo .con-timeline-classroom-icon i {
  font-size: 30px !important;
  line-height: 50px !important;
}
.con-timeline-classroom-assistindo p {
  color: #8ac6e9 !important;
  margin-left: -15% !important;
}
.con-timeline-classroom-end .con-timeline-classroom-icon {
  background: #ffb80f !important;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  text-align: center;
  top: 0 !important;
}
.con-timeline-classroom-end .con-timeline-classroom-icon i {
  font-size: 30px !important;
  line-height: 50px !important;
}
.con-timeline-classroom-end p {
  color: #ffb80f !important;
  margin-left: -15% !important;
  font-family: "Ubuntu Bold";
  font-size: 10.7px !important;
  font-weight: bold !important;
}

.con-classroom {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1400px;
}
.con-classroom .con-classroom-header {
  position: absolute;
  margin-top: -50px;
}
.con-classroom .con-classroom-body {
  position: relative;
}
.con-classroom .con-classroom-body .con-classroom-professores-list {
  padding-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;
}
.con-classroom .con-classroom-body .con-classroom-professores-list .row {
  flex-wrap: nowrap;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item {
  position: relative;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  p {
  font-size: 14px;
  font-weight: bold;
  color: #65abd3;
  margin-top: 10px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-classificacao {
  padding: 0px 30px;
  margin-top: 20px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-classificacao
  hr {
  border-color: #eee;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-classificacao
  p {
  color: #656565;
  margin-bottom: 2px;
  font-weight: bold;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-classificacao
  .questoes_respondidas {
  margin-top: -10px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-classificacao
  .rating-stars
  ul
  > li.star
  + li.star {
  margin-left: -7px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-classificacao
  .rating-stars
  ul
  > li.star
  > i.fa {
  font-size: 15px;
  margin-top: 10px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-estado {
  margin-top: -10px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-estado
  img {
  width: 30px;
  height: 20px;
  border-radius: 0px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-estado
  span {
  margin-top: -10px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-disciplina {
  padding: 0px 20px;
  margin-top: 20px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-disciplina
  label {
  /* Old browsers */
  background: -moz-linear-gradient(left, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #64c7eb 0%,
    #3ebbe3 51%,
    #23afd8 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  padding: 8px 40px;
  border-radius: 15px;
  color: #fff;
  font-family: "Ubuntu Bold";
  font-size: 18px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor
  .con-classroom-professores-list-item-professor-disciplina
  hr {
  border-color: #eee;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-header {
  background-color: #ececec;
  padding: 10px 2px 20px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Ubuntu Regular";
  color: #7c7c7c;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-body {
  background-color: #fff;
  border-top: 1px solid #ececec;
  min-height: 150px;
  position: relative;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  padding-bottom: 10px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-body:before {
  content: "";
  height: 30px;
  width: 101%;
  left: -1px;
  background-color: #fff;
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: -20px;
  position: absolute;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-body:after {
  content: "";
  height: 30px;
  width: 101%;
  bottom: -20px;
  left: -1px;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor-titulo {
  padding: 0px 20px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor-titulo
  p {
  color: #656565;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-professor-titulo
  div {
  border: 2px solid #edf2f0;
  border-radius: 2px;
  padding: 10px 20px;
  width: 100%;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-actions {
  padding: 0px 30px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-footer {
  background-color: #8ac6e9;
  padding: 30px 0px 15px 35px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-footer
  img {
  width: 40px;
}
.con-classroom
  .con-classroom-body
  .con-classroom-professores-list
  .con-classroom-professores-list-item
  .con-classroom-professores-list-item-footer
  span {
  font-family: "Ubuntu Bold";
  line-height: 16px;
  margin-left: 10px;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}

.btn-classroom {
  background-color: #d98840 !important;
  border-radius: 15px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff !important;
  font-size: 16px;
}
.btn-classroom:hover {
  color: #fff;
}
.btn-classroom img {
  width: 30px;
  margin-right: 3px;
}

.btn-classroom + .btn-classroom {
  margin-top: 10px;
}

.con-classroom-anotacoes {
  min-height: 150px;
  position: relative;
  padding-bottom: 10px;
}
.con-classroom-anotacoes:before {
  content: "";
  height: 30px;
  width: 100%;
  top: 40px;
  background-color: #fff;
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: absolute;
}
.con-classroom-anotacoes:after {
  content: "";
  height: 30px;
  width: 100%;
  bottom: 0px;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
}
.con-classroom-anotacoes-body {
  background-color: #fff;
  padding-top: 10px;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
}
.con-classroom-anotacoes-header {
  background-color: #ececec;
  padding: 10px 2px 20px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Ubuntu Medium";
  font-size: 20px;
  color: #7c7c7c;
}
.con-classroom-anotacoes ul {
  background-color: transparent;
  padding: 5px 20px 20px 20px;
}
.con-classroom-anotacoes ul li {
  list-style-type: none;
  border-bottom: 1px solid #ececec;
  color: #bcbcbc;
  font-family: "Ubuntu Regular";
  font-size: 14px;
  min-height: 30px;
}
.con-classroom-anotacoes ul li + li {
  margin-top: 10px;
}

.con-classroom-biblioteca-cta {
  margin-top: 20px;
}
.con-classroom-biblioteca-cta .btn-biblioteca {
  background-color: #d98840;
  border-radius: 50px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 30px;
}
.con-classroom-biblioteca-cta .btn-biblioteca:hover {
  color: #fff;
}
.con-classroom-biblioteca-cta .btn-biblioteca img {
  width: 30px;
  margin-right: 3px;
}

.con-classroom-aula-options {
  margin-top: 20px;
}
.con-classroom-aula-options .con-classroom-options-prev {
  /* Old browsers */
  background: -moz-linear-gradient(left, #e0e0e0 0%, #c9c9c9 51%, #b7b7b7 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #e0e0e0 0%,
    #c9c9c9 51%,
    #b7b7b7 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #e0e0e0 0%, #c9c9c9 51%, #b7b7b7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  margin-top: 20px;
  border-radius: 50px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 30px;
}
.con-classroom-aula-options .con-classroom-options-prev:hover {
  color: #fff;
}
.con-classroom-aula-options .con-classroom-options-next {
  margin-top: 20px;
  /* Old browsers */
  background: -moz-linear-gradient(left, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #64c7eb 0%,
    #3ebbe3 51%,
    #23afd8 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #64c7eb 0%, #3ebbe3 51%, #23afd8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border-radius: 50px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 30px;
}
.con-classroom-aula-options .con-classroom-options-next:hover {
  color: #fff;
}
.con-classroom-aula-options .con-classroom-options-next img {
  width: 30px;
  margin-right: 3px;
}
.con-classroom-aula-options .con-classroom-options-exit {
  margin-top: 20px;
  background-color: #f42727;
  border-radius: 50px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 30px;
}
.con-classroom-aula-options .con-classroom-options-exit:hover {
  color: #fff;
}
.con-classroom-aula-options .con-classroom-options-exit img {
  width: 30px;
  margin-right: 3px;
}

.con-classroom-ranking {
  position: relative;
  margin-top: 40px;
}
.con-classroom-ranking p {
  margin-bottom: 1px;
  font-size: 18px;
  font-family: "Ubuntu Regular";
  color: #323232;
}
.con-classroom-ranking .con-classroom-ranking-content {
  background-color: #ececec;
  border-radius: 8px;
}
.con-classroom-ranking .con-classroom-ranking-content::after {
  content: "";
  width: 100%;
  height: 20px;
  margin-top: -15px;
  background-color: #ececec;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  position: absolute;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-header {
  background-color: #e0e0e0;
  border-radius: 8px;
  border-radius: 8px;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-header
  .con-classroom-ranking-header-avatar {
  background-size: cover;
  background-position: center center;
  width: 45%;
  height: 60px;
  border-radius: 8px;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-header
  .con-classroom-ranking-header-level {
  margin-left: 20px;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-header
  .con-classroom-ranking-header-level
  .con-classroom-ranking-header-level-text {
  font-family: "Ubuntu Regular";
  font-size: 18px;
  line-height: 18px;
  margin-left: 10px;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-body {
  padding: 40px 20px 0px 20px;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-body
  .con-classroom-ranking-medals-list {
  list-style: none;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-body
  .con-classroom-ranking-medals-list
  li {
  display: inline-block;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-body
  .con-classroom-ranking-medals-list
  li
  img {
  margin-top: -20px;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-body
  .con-classroom-ranking-medals-list
  li
  + li {
  margin-left: 3px;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-body
  .con-classroom-ranking-trophies-list {
  list-style: none;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-body
  .con-classroom-ranking-trophies-list
  li {
  display: inline-block;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-body
  .con-classroom-ranking-trophies-list
  li
  img {
  margin-top: -25px;
}
.con-classroom-ranking
  .con-classroom-ranking-content
  .con-classroom-ranking-body
  .con-classroom-ranking-trophies-list
  li
  + li {
  margin-left: 3px;
}
.con-classroom-ranking .con-classroom-ranking-verregras {
  background-color: #d98840;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  padding: 8px 20px;
  box-shadow: none;
  font-family: "Ubuntu Bold";
  font-style: normal;
  letter-spacing: initial;
  color: #fff;
  font-size: 30px;
}
.con-classroom-ranking .con-classroom-ranking-verregras:hover {
  color: #fff;
}

.plyr--video {
  border-radius: 20px;
  background-color: transparent !important;
}

.plyr__video-wrapper {
  background-color: transparent !important;
}

.con-aulas-curso-list-item-icon *:hover {
  text-decoration: none !important;
}

/*# sourceMappingURL=styles.css.map */
</style>
